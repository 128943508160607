// @flow

import React from 'react'
import type { Node } from 'react'

import styled, { css } from 'styled-components'
import cx from 'classnames'
import { rgba } from 'polished'

import tw from '../../tailwind'
import { formatAllPosts } from '../helpers'

import { InlineLink as Link } from '../components/links'
import Layout from '../components/layout'
import DefaultPage from '../components/default-page'
import { useAllPosts } from '../context'

type BlogListLinkProps = {
  content: {
    id: string,
    url: string,
    title: Node,
    publishDate: string,
  },
}

function BlogListLink({ content }: BlogListLinkProps) {
  const { url, title, publishDate } = content
  return (
    <StyledListItem>
      <StyledLink to={url} color="text-grey-darker rounded p-3 block">
        <div className="mb-2 leading-tight">{title}</div>
        <p className="text-sm text-grey _subtitle">{publishDate}</p>
      </StyledLink>
    </StyledListItem>
  )
}

type BlogParagraphProps = {
  children: Node,
  className?: string,
}

function BlogParagraph({ children, className }: BlogParagraphProps) {
  return (
    <p className={cx('text-grey-darker text-lg leading-tight', className)}>
      {children}
    </p>
  )
}
BlogParagraph.defaultProps = {
  className: undefined,
}

function Header() {
  return (
    <header className="mb-10">
      <h1 className="text-3xl md:text-4xl leading-tight font-bold mb-4">
        Blog Posts
      </h1>
      <BlogParagraph>
        Get the latest about local culture, on-going festivities, events and
        more!{' '}
        <span role="img" aria-label="a lonely palm">
          🌴
        </span>
        <span role="img" aria-label="sun">
          ☀️
        </span>
        <span role="img" aria-label="a surfer">
          🏄‍♀️
        </span>
      </BlogParagraph>
    </header>
  )
}

function EmptyState() {
  return (
    <div className="flex items-center p-3 bg-red-lightest rounded">
      <div className="border-l-2 border-solid border-red-light h-12 pr-4" />
      <BlogParagraph className="text-red">
        The blog is currently offline.
      </BlogParagraph>
    </div>
  )
}

function BlogList() {
  const allPosts = useAllPosts()
  const posts = formatAllPosts(allPosts.edges)

  if (posts.length !== 0) {
    return (
      <ul>
        {posts.map(({ id, title, publishDate, url }) => (
          <BlogListLink key={id} content={{ id, title, publishDate, url }} />
        ))}
      </ul>
    )
  }

  return <EmptyState />
}

function BlogPage(): Node {
  return (
    <Layout>
      <DefaultPage>
        <div className="py-16">
          <Header />
          <main>
            <div className="-mx-3">
              <BlogList />
            </div>
          </main>
        </div>
      </DefaultPage>
    </Layout>
  )
}

export default BlogPage

const StyledListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`

const linkColor = tw.colors['purple-light']
const transitionCSS = css`
  transition-property: color, box-shadow, border;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
  transition-delay: 60ms;
`
const StyledLink = styled(Link)`
  border: 1px solid transparent;
  ${transitionCSS};

  & ._subtitle {
    ${transitionCSS};
  }

  &:hover {
    border: 1px solid ${rgba(linkColor, 0.5)};
    color: ${tw.colors.purple};

    & ._subtitle {
      color: ${tw.colors['grey-dark']};
    }
  }
`
